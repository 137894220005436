import axios from 'axios'
import moment from 'moment'

// const getCircularReplacer = () => {
//   const seen = new WeakSet()
//   return (key, value) => {
//     if (typeof value === 'object' && value !== null) {
//       if (seen.has(value)) {
//         return
//       }
//       seen.add(value)
//     }
//     return value
//   }
// }

/**
 * @callback Call
 * @param {IORedis.Redis} [redis]
 * @param {number} [expires] Minutes
 * @returns {Promise<CallResponse>}
 */

/**
 * @typedef CallResponse
 * @property {boolean} success
 * @property {number} status
 * @property {string} message
 * @property {*} result
 */

/**
 * Base API call
 * @param {AxiosRequestConfig} options AXIOS params
 * @returns {Call} Func accepts a redis connector and an expires cache time props
 */
export const call = options => async redis => {
  // const body = options.method === 'post' || options.method === 'patch' ? { body: options?.data } : {}

  try {
    if (redis) {
      const redisUpdates = await redis.get(`${options.url}_updated`)
      const redisReponse = await redis.get(options.url)

      if (redisUpdates) {
        const updateDate = JSON.parse(redisUpdates)
        const dateNow = moment(new Date(), 'MM-DD-YYYY HH:mm').utc()

        const minuteLifeKey = dateNow.diff(updateDate, 'minutes')

        if (minuteLifeKey <= 60 && redisReponse) {
          return { success: true, status: 200, message: 'REDIS', result: JSON.parse(redisReponse) }
        }
      }
    }

    const response = await axios({ ...(options || {}) })

    if (
      redis &&
      response.status >= 200 &&
      response.status < 300 &&
      !response?.data?.message &&
      !response?.data?.error &&
      !(typeof response?.data === 'string' && response?.data?.includes('connection failure')) &&
      !(typeof response?.data === 'string' && response?.data?.includes('error: 111')) &&
      !(typeof response?.data === 'string' && response?.data?.includes('connect error')) &&
      !(typeof response?.data === 'string' && response?.data === '')
    ) {
      await redis.set(options.url, JSON.stringify(response.data))
      await redis.set(`${options.url}_updated`, JSON.stringify(moment(new Date(), 'MM-DD-YYYY HH:mm').utc()))
    }

    if (
      response.status >= 200 &&
      response.status < 300 &&
      !response?.data?.message &&
      !response?.data?.error &&
      !(typeof response?.data === 'string' && response?.data?.includes('connection failure')) &&
      !(typeof response?.data === 'string' && response?.data?.includes('error: 111')) &&
      !(typeof response?.data === 'string' && response?.data?.includes('connect error')) &&
      !(typeof response?.data === 'string' && response?.data === '')
    ) {
      return { success: true, status: response.status, message: response.statusText, result: response?.data }
    }

    if (redis) {
      const redisReponse = await redis.get(options.url)

      if (redisReponse) {
        // await axios({
        //   method: 'post',
        //   url: 'https://us-central1-benchmark-media.cloudfunctions.net/trackWebsitetErrors',
        //   data: {
        //     timestamp: Date.now(),
        //     websiteUrl: options.url,
        //     error: {
        //       error: response?.data?.message || response,
        //       queryParam: queryWeb || urlError || '',
        //       ...body
        //     }
        //   }
        // }).then(data => JSON.stringify(data, getCircularReplacer()))

        return { success: true, status: 200, message: 'REDIS', result: JSON.parse(redisReponse) }
      }
    }

    if (response.data?.error) {
      // await axios({
      //   method: 'post',
      //   url: 'https://us-central1-benchmark-media.cloudfunctions.net/trackWebsitetErrors',
      //   data: {
      //     timestamp: Date.now(),
      //     websiteUrl: options.url,
      //     error: {
      //       error: response?.data,
      //       queryParam: queryWeb || urlError || '',
      //       ...body
      //     }
      //   }
      // }).then(data => JSON.stringify(data, getCircularReplacer()))

      return {
        success: false,
        status: response.status,
        message: response?.data?.message || response,
        result: null
      }
    }

    if (response?.data?.message) {
      // await axios({
      //   method: 'post',
      //   url: 'https://us-central1-benchmark-media.cloudfunctions.net/trackWebsitetErrors',
      //   data: {
      //     timestamp: Date.now(),
      //     websiteUrl: options.url,
      //     error: {
      //       error: response?.data?.message,
      //       queryParam: queryWeb || urlError || '',
      //       ...body
      //     }
      //   }
      // }).then(data => JSON.stringify(data, getCircularReplacer()))

      return {
        success: false,
        status: response.status,
        message: response?.data?.message || response,
        result: null
      }
    }
  } catch (error) {
    // await axios({
    //   method: 'post',
    //   url: 'https://us-central1-benchmark-media.cloudfunctions.net/trackWebsitetErrors',
    //   data: {
    //     timestamp: Date.now(),
    //     websiteUrl: options.url,
    //     error: {
    //       error: error.response?.data ? error.response?.data : error.response,
    //       queryParam: queryWeb || urlError || '',
    //       ...body
    //     }
    //   }
    // }).then(data => JSON.stringify(data, getCircularReplacer()))

    if (redis) {
      const redisReponse = await redis.get(options.url)

      if (redisReponse || redisReponse?.data) {
        return { success: true, status: 200, message: 'REDIS', result: JSON.parse(redisReponse) }
      }
    }

    // The request was made and the server responded with a status code that falls out of the range of 2xx
    if (error.response) {
      return { success: false, status: error.response.status, message: error.response?.data, result: null }
    }
    // The request was made but no response was received
    if (error.request) {
      return { success: false, status: null, message: 'API response is not received', result: null }
    }
    // Something happened in setting up the request and triggered an Error
    return { success: false, status: null, message: 'Unknown error', result: null }
  }
}

/**
 * Accepts query and pass redis params to the call
 * @param {IORedis.Redis|null} redis
 * @param {Call} query Function from api/calls folder
 * @param {string} [queryWeb] link web
 * @returns {Promise<CallResponse>}
 */
export const withRedis = (redis, query, queryWeb) => query(redis, queryWeb)
