import { useSelector } from 'react-redux'

import HeaderMenu from './HeaderMenu'

const HeaderMenuContainer = props => {
  const { isLogged } = useSelector(({ auth }) => auth)
  const { askExpertPopup } = useSelector(({ app }) => app)

  const updatedProps = {
    ...props,
    isLogged,
    askExpertPopup
  }

  return <HeaderMenu {...updatedProps} />
}

export default HeaderMenuContainer
