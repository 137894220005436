import { call } from 'api/callConstructor'
import { API_CLOUDFUNCTIONS } from 'api/apiConstants'

export const getReviewsCall = (data, urlError) => {
  const url = `${API_CLOUDFUNCTIONS}/getReviews`
  return call({ method: 'post', url, data }, urlError)
}

export const sendQuizEmailCall = (data, signal) => {
  const url = `${API_CLOUDFUNCTIONS}/widgetQuizEmails`
  return call({ method: 'post', url, data, signal })
}

export const addReviewCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/addReview`
  return call({ method: 'post', url, data })
}

export const subscribeNewsLetterCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/subscribeNewsletters`
  return call({ method: 'post', url, data })
}

export const subscribeUserCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/subscribeNewsletters`
  return call({ method: 'post', url, data })
}

export const getAssetRankCall = slug => {
  const url = `${API_CLOUDFUNCTIONS}/getIndustryRank`
  return call({ method: 'post', url, data: { slug } })
}
export const voteForCollectionCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/voteForCollectionEntry`
  return call({ method: 'post', url, data })
}

export const getCollectionVotesCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/getCollectionVotesBySlug`
  return call({ method: 'post', url, data })
}
