import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { Menu, MenuItem, useWindowSize, Icon } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import { ROUTES } from 'static/menusConstants'
import UserAvatar from 'components/ui/UserAvatar/UserAvatarContainer'
import styles from './HeaderAuth.module.scss'

const HeaderAuth = ({ className, isLogged, handleOpenModal }) => {
  const { push, pathname } = useRouter()
  const [isMenu, setMenu] = useState(null)
  const { screenWidth } = useWindowSize()

  useEffect(() => {
    setMenu(null)
  }, [isLogged])

  const handleMenuClick = link => {
    push(link)
    // Close menu
    setMenu(null)
  }

  // Logout form firebase
  const handleLogoutGlobal = () => {
    window['private-router'] = null

    // Close menu
    setMenu(null)
  }

  return (
    <section className={`${className} ${styles.HeaderAuth}`}>
      {!isLogged ? (
        <>
          <button
            type="button"
            style={{ color: '#fff' }}
            className={styles.LoginBtn}
            onClick={event => setMenu(event.currentTarget)}
          >
            <Icon icon="icon-user" size={24} />
          </button>

          <Menu
            isOpen={isMenu}
            position={screenWidth <= 960 && screenWidth >= 601 ? 'top' : 'bottom-right'}
            handleClose={() => setMenu(null)}
          >
            {!pathname.includes(ROUTES.login) && (
              <MenuItem
                onClick={() =>
                  pathname.includes(ROUTES.signup) ? push(`/${ROUTES.login}`) : handleOpenModal('signIn')
                }
              >
                Login
              </MenuItem>
            )}

            {!pathname.includes(ROUTES.signup) && (
              <MenuItem
                onClick={() =>
                  pathname.includes(ROUTES.login) ? push(`/${ROUTES.signup}`) : handleOpenModal('signUp')
                }
              >
                Sign Up
              </MenuItem>
            )}
          </Menu>
        </>
      ) : (
        <>
          <button type="button" className={styles.UserBtn} onClick={event => setMenu(event.currentTarget)}>
            <UserAvatar />
          </button>

          <Menu
            position={screenWidth <= 960 && screenWidth >= 601 ? 'top' : 'bottom-right'}
            isOpen={isMenu}
            handleClose={() => setMenu(null)}
            className={styles.Menu}
          >
            <MenuItem onClick={() => handleMenuClick(`/${ROUTES.favorites}`)}>Favorites</MenuItem>
            <MenuItem onClick={() => handleMenuClick(`/${ROUTES.userSettings}`)}>Settings</MenuItem>
            <MenuItem onClick={() => handleLogoutGlobal()}>Log Out</MenuItem>
          </Menu>
        </>
      )}
    </section>
  )
}

HeaderAuth.propTypes = {
  className: PropTypes.string,
  isLogged: PropTypes.bool,
  handleOpenModal: PropTypes.func
}

HeaderAuth.defaultProps = {
  className: '',
  isLogged: false,
  handleOpenModal: () => {}
}

export default HeaderAuth
