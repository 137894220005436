import { useCallback, useEffect, memo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon, useWindowSize } from '@moneymade/moneymade-ui'
import { Input } from '@sajari/react-search-ui'
import { useSearch, useQuery } from '@sajari/react-hooks'
import { useRouter } from 'next/router'

import Logo from 'components/ui/Logo/Logo'
import HeaderMenu from 'components/header/Header/HeaderMenu/HeaderMenuContainer'
import HeaderMob from 'components/header/HeaderMob/HeaderMob'
import ResultInputModal from 'components/header/Header/ResultInputModal/ResultInputModal'
import DiscoverModal from 'components/header/DiscoverModal/DiscoverModal'
import styles from './Header.module.scss'

const Header = ({ beforeScroll: { primaryColor }, landing, isLogged, pratformsHeader, readHeader }) => {
  const { screenWidth } = useWindowSize()
  const { pathname } = useRouter()
  const [mobileMenu, setMobileMenu] = useState(false)
  const [openDiscoverModal, setOpenDiscoverModal] = useState(false)
  const [hideMenu, setHideMenu] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const elRef = useRef(null)

  const handleScroll = useCallback(() => {
    // Show header if scroll position at the top on the screen
    if (window.scrollY > 50 && mobileMenu) setMobileMenu(false)
  }, [mobileMenu])

  // const handleHideHeader = useCallback(, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const handleControlNavbar = useCallback(() => {
    if (pathname === '/learn/article/[slug]') {
      const currentScrollPos = window.scrollY

      if (currentScrollPos > 15) {
        setHideMenu(!(currentScrollPos > prevScrollPos))
        setPrevScrollPos(currentScrollPos)
      }
    } else {
      setHideMenu(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, prevScrollPos])

  useEffect(() => {
    window.addEventListener('scroll', handleControlNavbar)
    return () => window.removeEventListener('scroll', handleControlNavbar)
  }, [handleControlNavbar, pathname])

  const { results } = useSearch()
  const { query, setQuery } = useQuery()

  const handleClick = () => setQuery('')

  return (
    <>
      <header className={styles.Header} data-hide={!hideMenu}>
        <Logo logoType="light" smallLogo landing={landing} />

        {!landing ? (
          <>
            <div ref={elRef}>
              <Input
                placeholder={screenWidth < 500 ? 'Crypto, Wine...' : 'Crypto, Farmland, Fine wine...'}
                minimumCharacters={3}
                className="customInput"
                autoComplete="off"
              />
            </div>
            <HeaderMenu
              primaryColor="red"
              textColor="dark"
              mobileMenu={mobileMenu}
              openDiscoverModal={openDiscoverModal}
              handleOpenDiscover={() => setOpenDiscoverModal(true)}
            />
            {screenWidth <= 1284 && (
              <div className={styles.MobNav}>
                <button
                  type="button"
                  className={`${styles.MobBtn} ${mobileMenu ? styles.Open : ''}`}
                  onClick={() => setMobileMenu(prev => !prev)}
                >
                  <Icon icon={mobileMenu ? 'icon-x-mark' : 'icon-hamburger-menu'} size={mobileMenu ? 14 : 19} />
                </button>
              </div>
            )}
          </>
        ) : null}
      </header>

      {query ? <ResultInputModal resultinput={results} handleClose={handleClick} inputRef={elRef} /> : null}

      {mobileMenu && (
        <HeaderMob
          isLogged={isLogged}
          primaryColor={primaryColor}
          investmentsHeader={pratformsHeader}
          readHeader={readHeader}
          handleSetMobileMenu={setMobileMenu}
        />
      )}

      {openDiscoverModal && (
        <DiscoverModal
          investmentsHeader={pratformsHeader}
          readHeader={readHeader}
          handleClose={() => setOpenDiscoverModal(false)}
        />
      )}
    </>
  )
}

Header.propTypes = {
  beforeScroll: PropTypes.shape({
    primaryColor: PropTypes.oneOf(['red', 'dark']),
    textColor: PropTypes.oneOf(['light', 'dark']),
    transparent: PropTypes.bool
  }),
  isLogged: PropTypes.bool,
  askExpertPopup: PropTypes.shape({
    status: PropTypes.bool,
    params: PropTypes.string
  }),
  landing: PropTypes.bool,
  pratformsHeader: PropTypes.arrayOf(PropTypes.object),
  readHeader: PropTypes.arrayOf(PropTypes.object)
}

Header.defaultProps = {
  beforeScroll: {
    primaryColor: 'red',
    textColor: 'dark',
    transparent: false
  },
  isLogged: false,
  askExpertPopup: {
    status: false,
    params: ''
  },
  landing: false,
  pratformsHeader: [],
  readHeader: []
}

export default memo(Header)
