import { getDecimalAdjustmentOfNumber } from 'utils/getters'
import { toMoneyFormat } from 'utils/helpers'

// @Ruslan - I belive we can replace it with toMoney format function of sililar. Doublecheck and if not, move the function to local utils
export const toNumberWithDecimalsAfterPoint = val => {
  if (val.indexOf('.') > -1) {
    if (val.split('.')[1].length === 2) return val
    return `${val}0`
  }
  return `${val}.00`
}

// @Ruslan - is is terrible solution with the 3 functions on in another. Replace with the existing functions or rework it
export const toDecimalsCorrect = value =>
  toNumberWithDecimalsAfterPoint(toMoneyFormat(getDecimalAdjustmentOfNumber('round', value, -2)))

const isNegativeNumber = value => value.indexOf('-') === 0

// @Ruslan - I belive we can replace it with toPosOrNeg
export const toSignCorrectDisplayValue = value =>
  isNegativeNumber(value) ? `-$${value.replace('-', '')}` : value === '0.00' ? `$${value}` : `+$${value}`

// @Ruslan - you dont need to have a function for this. Remove the function and do the action without the function
export const toWithoutSpaces = string => String(string).replaceAll(',', '')

const toShuffle = array => {
  let currentIndex = array.length
  let temporaryValue
  let randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    // @Ruslan
    // eslint-disable-next-line no-param-reassign
    array[currentIndex] = array[randomIndex]
    // @Ruslan
    // eslint-disable-next-line no-param-reassign
    array[randomIndex] = temporaryValue
  }

  return array
}

// @Ruslan - the function is used once or twice. We dont need the fucntion in utils. Move or just use functionality in the right places
const toPremiumAndPaid = investments => [
  ...toShuffle(investments.filter(({ premium }) => premium)),
  ...toShuffle(investments.filter(({ premium, paid }) => paid && !premium))
]

// @Ruslan - the function is used once or twice. We dont need the fucntion in utils. Move or just use functionality in the right places
export const toLimitedSort = investments => {
  // Split by industry
  const byTypes = investments.reduce((acc, investment) => {
    acc[investment.industry] = [...(acc[investment.industry] || []), investment]
    return acc
  }, {})
  // Sort arrays by premium
  const byPremium = Object.values(byTypes).map(investment => toPremiumAndPaid(investment))
  // Get in all arrays only three elements
  const byTwo = byPremium.map(investmentsArray => investmentsArray.slice(0, 2))
  // Merge arrays
  // @Ruslan fix this
  // eslint-disable-next-line prefer-spread
  const byMerge = [].concat.apply([], byTwo)

  return toPremiumAndPaid(byMerge)
}

export const toConvertMoney = val => {
  if (val < 1e3) return val
  if (val >= 1e3 && val < 1e6) return `${+(val / 1e3).toFixed(1)}K`
  if (val >= 1e6 && val < 1e9) return `${+(val / 1e6).toFixed(1)}M`
  if (val >= 1e9 && val < 1e12) return `${+(val / 1e9).toFixed(1)}B`
  if (val >= 1e12 && val < 1e15) return `${+(val / 1e12).toFixed(1)}T`
  if (val >= 1e15 && val < 1e18) return `${+(val / 1e15).toFixed(1)}Q`
  if (val >= 1e18 && val < 1e21) return `${+(val / 1e18).toFixed(1)}Qu`
  if (val >= 1e21 && val < 1e24) return `${+(val / 1e21).toFixed(1)}S`
  if (val >= 1e24 && val < 1e27) return `${+(val / 1e24).toFixed(1)}Se`
  if (val >= 1e27 && val < 1e30) return `${+(val / 1e27).toFixed(1)}O`
  if (val >= 1e30 && val < 1e33) return `${+(val / 1e30).toFixed(1)}N`
  if (val >= 1e33) return `${+(val / 1e33).toFixed(1)}D`

  return val
}

export const toPaidOrderStatic = investments => [
  ...investments.filter(({ premium }) => premium),
  ...investments.filter(({ premium, paid }) => paid && !premium),
  ...investments.filter(({ premium, paid }) => !paid && !premium)
]

// @Ruslan - the function is used once or twice. We dont need the fucntion in utils. Move or just use functionality in the right places
export const parsedFeedTags = newsTags =>
  newsTags?.map(tag => (tag?.includes('&') ? tag?.replace('&', '%26') : tag))?.join(',') || ''

export const toThumbnailUrl = (slug = '') => `${process.env.NEXT_PUBLIC_API_THUMBNAILS_URL}/${slug.toLowerCase()}.jpg`

export const shuffleArray = array =>
  array
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

export const sortEndtries = investments => {
  // // Split by categories
  const splited = investments.reduce(
    (acc, platform) => {
      if (platform.mmPremium) {
        return { ...acc, mmPremium: [...acc.mmPremium, platform] }
      }
      if (platform.premium) {
        return { ...acc, premium: [...acc.premium, platform] }
      }
      if (platform.paid) {
        return { ...acc, paid: [...acc.paid, platform] }
      }

      if (platform?.profile?.verificationStatus === 'Verified Paying (Premium)') {
        return { ...acc, newPaid: [...acc.newPaid, platform] }
      }

      if (platform?.profile?.verificationStatus === 'Verified Not Paying') {
        return { ...acc, notPaid: [...acc.notPaid, platform] }
      }
      return { ...acc, other: [...acc.other, platform] }
    },
    {
      mmPremium: [],
      premium: [],
      paid: [],
      newPaid: [],
      notPaid: [],
      other: []
    }
  )
  // [premium -> paid -> other] and shuffle

  return [
    ...shuffleArray(splited.mmPremium),
    ...shuffleArray(splited.premium),
    ...shuffleArray(splited.newPaid),
    ...shuffleArray(splited.notPaid),
    ...shuffleArray(splited.paid),
    ...shuffleArray(splited.other)
  ].filter(item => item?.publishedStatus !== 'draft')
}

export const convertLinktoSlug = postUrl => {
  const urlParts = postUrl.split('/')

  // Get the last part of the URL (after the last '/')
  const lastPart = urlParts[urlParts.length - 1]

  // Remove any query parameters or fragments
  const result = lastPart.split('?')[0].split('#')[0]
  return result || ''
}
